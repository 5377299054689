<template>
  <div class="system-to-ground">
    <ContactInfo></ContactInfo>
    <img
      class="animate__animated"
      v-scroll="'animate__fadeIn'"
      src="@assets/images/SystemToGround1.png"
      alt=""
      width="100%"
    />
    <div class="number1">
      <img src="@assets/images/SystemBuild2.png" alt="" width="100%" />
      <div class="contain">
        <div class="Rem0_4 animate__animated" v-scroll="'animate__fadeInDown'">
          <p>景翔体系落地介绍</p>
        </div>
        <div class="animate__animated" v-scroll="'animate__fadeIn'">
          <p class="Rem0_24">
            管理体系建设最终目的是为了管理制度能够得到有效落地，但是体系落地一直都是企业管理中传将的难题。景翔云体系平台的“体系落地检查”统块，是我司近三十年辅导企业建立和实施管理体体系文件编写体系的经验，针对“建立容易落地难”、“检查工系作流于形式”的老大难问题，专门开发的落地检体系运行旁查软件系统，通过将检查过程信息化数字化，是此企业落实管理体系和工作制度的有效手段，并提体系内审高检查工作的有效性和效率!
          </p>
          <div>
            <img
              class="animate__animated"
              v-scroll="'animate__fadeIn'"
              src="@assets/images/SystemToGround3.png"
              alt=""
            />
            <div class="text">
              <p class="p1">传统体系落地</p>
              <div class="pbox1">
                <p>体系文件编写</p>
                <p>体系运行</p>
                <p>体系内审</p>
              </div>
              <div class="pbox2">
                <p>体系文件编写</p>
                <p>题库体系建立</p>
                <p>题库学习考试</p>
                <p>体系运行</p>
                <p>云体系平台落地检查</p>
              </div>
              <p class="p2">景翔体系落地</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="number2">
      <img src="@assets/images/SystemToGround2.png" alt="" width="100%" />

      <div class="contain">
        <div class="Rem0_4 animate__animated" v-scroll="'animate__fadeInDown'">
          <p style="color: #4375c8">
            “体系落地检查”系统如何解决体系落地面临的问题
          </p>
          <p></p>
        </div>
        <div class="animate__animated" v-scroll="'animate__fadeInLeftBig'">
          <p class="Rem0_28"><span>1</span>体系建立容易落地难</p>
          <p class="Rem0_21">
            系统可以对体系落地检查、现场6S检查、供应商审核等检查内容进行策划，可以制作检查计划，或确定某些检查
            项目的周期，自动生成检查计划，检查计划可以直接分配任务给检查员
          </p>
        </div>
        <div class="animate__animated" v-scroll="'animate__fadeInRightBig'">
          <p class="Rem0_28"><span>2</span>检查工作流于形式</p>
          <p class="Rem0_21">
            检查人员按照系统的流程和检查表进行检查工作，每一个步骤的记录都能够在系统上查阅，每个检查人员和被检查人员都有权查阅，可以起到互相监督的作用。
          </p>
        </div>
        <div class="animate__animated" v-scroll="'animate__fadeInLeftBig'">
          <p class="Rem0_28"><span>3</span>检查内容干篇一律</p>
          <p class="Rem0_21">
            系统拥有“检查题库”功能，事先根据企业的体系文件录入检查题库，现场审核的时候随机从题库中抽取一定数
            量的问题进行检查，让被检查人员防不胜防。
          </p>
        </div>
        <div class="animate__animated" v-scroll="'animate__fadeInRightBig'">
          <p class="Rem0_28"><span>4</span>检查人员不熟悉被检查业务</p>
          <p class="Rem0_21">
            系统拥有“学习中心”功能，可以根据企业的文件生成“应知应会”。检查人员可以事先了解学习，并且有考试
            功能。
          </p>
        </div>
        <div class="animate__animated" v-scroll="'animate__fadeInLeftBig'">
          <p class="Rem0_28"><span>5</span>检查人员能力不足</p>
          <p class="Rem0_21">
            系统通过提供“检查题库”和“学习中心”功能，可以迅速提高检查人员的能力，使其更加了解被检查业务的流
            程。
          </p>
        </div>
        <div class="animate__animated" v-scroll="'animate__fadeInRightBig'">
          <p class="Rem0_28"><span>6</span>发现问题跟踪困难</p>
          <p class="Rem0_21">
            发现问题在系统中自动汇总，自动跟踪。将问题的采取临时措施、原因分析，采取整改措施，措施验证等流程自
            动化，发送给相关的人员填写，领导审批，如果超过时限，领导可以看到提示。
          </p>
        </div>
      </div>
    </div>
    <div class="number3">
      <div class="Rem0_4 animate__animated" v-scroll="'animate__flipInY'">
        <p style="color: #4375c8">了解我们的产品</p>
        <p></p>
      </div>
      <div class="tab animate__animated" v-scroll="'animate__fadeIn'">
        <span
          v-for="(item, index) in carouselDiv.tab"
          :key="index"
          @click="clickCarouselTab(index)"
          :class="{ carouselActive: index == carouseltabIndex }"
          class="Rem0_26"
          >{{ item }}</span
        >
      </div>

      <div>
        <div class="carousel-img">
          <el-carousel
            class="animate__animated"
            v-scroll="'animate__fadeInDownBig'"
            trigger="click"
            height="480px"
            ref="carouse"
            @change="changeCarousel"
          >
            <el-carousel-item
              v-for="(item, index) in carouselDiv.content"
              :key="index"
            >
              <img :src="item.img" alt="" />
            </el-carousel-item>
          </el-carousel>
          <div class="animate__animated" v-scroll="'animate__fadeInUpBig'">
            <p class="p1 Rem0_30">{{ consultItem.p1 }}</p>
            <img
              class="lineimg"
              src="../../../assets/images/ProcessOptimize21.png"
              alt
            />
            <p class="p2 Rem0_22">
              {{ consultItem.p2 }}
            </p>
            <span class="CustomButton" @click="$router.push('./trial-use')"
              >免费使用</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="number4">
      <img src="@assets/images/SystemBuild2.png" alt="" width="100%" />
      <div class="contain">
        <div class="Rem0_4">
          <p style="color: white !important;">我们的服务</p>
          <p style="background: white;"></p>
        </div>
        <div class="ul">
          <span
            class="Rem0_25 animate__animated"
            v-scroll="'animate__fadeInLeftBig'"
            style="animation-delay: 1s"
            v-for="(item, index) in serveList.list1"
            :key="index"
          >
            {{ item }}</span
          >
        </div>
        <div class="ul">
          <span
            class="Rem0_25 animate__animated"
            v-scroll="'animate__fadeInLeftBig'"
            style="animation-delay: 1s"
            v-for="(item, index) in serveList.list2"
            :key="index"
          >
            {{ item }}</span
          >
        </div>
        <div class="ul">
          <span
            class="Rem0_25 animate__animated"
            v-scroll="'animate__fadeInLeftBig'"
            style="animation-delay: 1s"
            v-for="(item, index) in serveList.list3"
            :key="index"
          >
            {{ item }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import animateScroll from "../../../modules/AnimateScroll.js";
import ContactInfo from "../../../components/public/ContactInfo.vue";
export default {
  components: { ContactInfo },
  data() {
    return {
      carouseltabIndex: 0,
      consultItem: {},
      carouselDiv: {
        tab: ["云体系平台", "落地检查", "常规审核", "学习测试"],
        content: [
          {
            img: require("@assets/images/SystemToGround4.png"),
            p1: "景翔云体系平台系平台",
            p2: "景翔云体系平台是我司近三十年辅导企业建立和实施管理体系的经验，针对“建立容易落地难”、“检查工作流于形式”的老大难问题，专门开发的落地检查软件系统，通过将检查过程信息化数字化，是企业落实管理体系和工作制度的有效手段，并提高检查工作的有效性和效率!",
          },
          {
            img: require("@assets/images/SystemToGround5.png"),
            p1: "体系落地检查流程图",
            p2: "市场部补说明",
          },
          {
            img: require("@assets/images/SystemToGround6.png"),
            p1: "常规标准审核界面",
            p2: "可以抽取标准体系题库，也可以抽取企业自有题库，生成问题检查计划和检查清单",
          },
          {
            img: require("@assets/images/SystemToGround7.png"),
            p1: "学习界面和业务测评界面",
            p2: "可以抽取标准体系题库，也可以抽取企业自有题库，生成问题检查表和审核计划",
          },
        ],
      },
      serveList: {
        list1: [
          "构建工作标准 ",
          "构建体系落地检查题库",
          "构建个人工作看板",
          "体系落地检查",
          "现场7S检查",
        ],
        list2: [
          "现场安全检查 ",
          "质量管理体系成熟度评价",
          "协助ISO管理体系审核",
          "其他管理体系审核",
          "IATF16949审核",
        ],
        list3: [
          "VDA6.3过程审核 ",
          "VDA6.5产品审核",
          "潜在供应商审核",
          "关键供应商审核",
          "培训课程",
        ],
      },
    };
  },
  animateScroll,
  mounted() {
    this.changeCarousel(0);
  },
  methods: {
    clickCarouselTab(index) {
      this.carouseltabIndex = index;
      this.$refs.carouse.setActiveItem(index);
    },
    changeCarousel(index) {
      this.carouseltabIndex = index;
      this.consultItem = this.carouselDiv.content[index];
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../assets/style/FontSize.css";
.system-to-ground {
  @media screen and (min-width: 1200px) and (max-width: 1519px) {
    .number3 {
      .carousel-img {
        .el-carousel {
          width: 45% !important;
          height: 450px !important;
        }
        .CustomButton {
          bottom: 35% !important;
        }
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .number3 {
      margin-bottom: -50px !important;
      padding-top: 50px !important;
      .carousel-img {
        .el-carousel {
          width: 40% !important;
          height: 380px !important;
        }
        .CustomButton {
          bottom: 50% !important;
        }
      }
    }
  }
  @media screen and (min-width: 320px) and (max-width: 767px) {
  }
  .Rem0_4 {
    padding: 5% 0 2% 0;
    text-align: center;
    p:nth-of-type(2) {
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .number1 {
    position: relative;
    height: 100vh;
    > img:nth-of-type(1) {
      height: 100vh;
    }
    img {
      position: absolute;
    }
    .contain {
      position: absolute;
      top: 0;
      z-index: 9999;
      color: white;
      .Rem0_4 {
        color: white;
      }
      div:nth-of-type(2) {
        display: flex;
        width: 100%;
        justify-content: center;
        > .Rem0_24 {
          text-indent: 38px;
          padding-top: 4%;
          margin:0 3%;
          width: 30%;
          line-height: 0.5rem !important;
        }
        img {
          width: 9.5rem;
        }
        .text {
          position: relative;
          width: 9.3rem;
          height: 4.9rem;
          p {
            position: absolute;
            font-size: 0.3rem;
            display: inline-block;
            cursor: default;
          }
          .p1 {
            left: 3.5%;
            top: 25%;
            writing-mode: vertical-rl;
            letter-spacing: 15px;
          }
          .p2 {
            writing-mode: vertical-rl;
            font-size: 0.36rem !important;
            letter-spacing: 15px;
            right: 3.5%;
            top: 20%;
          }
          .pbox1 {
            color: #3084c1;
            p:hover {
              font-weight: bold;
              color: #fd7a24;
            }
            p:nth-of-type(1) {
              left: 17%;
              top: 48.5%;
            }
            p:nth-of-type(2) {
              left: 17%;
              top: 68.5%;
            }
            p:nth-of-type(3) {
              left: 17%;
              top: 87.5%;
            }
          }
          .pbox2 {
            p {
              background: #f7f0f0;
              width: 2.85rem;
              border-radius: 50px 0 0 50px;
              padding: 0.18rem 0 0.18rem 0.5rem;
            }
            p:hover {
              font-weight: bold;
              color: #2a8fd7;
              background: #c9e3f6;
            }
            color: #fd7a24;
            p:nth-of-type(1) {
              right: 12%;
              top: 5%;
            }
            p:nth-of-type(2) {
              right: 12%;
              top: 25%;
            }
            p:nth-of-type(3) {
              right: 12%;
              top: 45%;
            }
            p:nth-of-type(4) {
              right: 12%;
              top: 65%;
            }
            p:nth-of-type(5) {
              right: 12%;
              top: 85%;
            }
          }
        }
      }
    }
  }
  .number2 {
    height: 100vh;
    img {
      position: absolute;
      height: 100vh;
    }
    .contain {
      position: absolute;
      width: 60%;
      margin: 0 20%;
      div {
        margin-bottom: 1%;
        .Rem0_28 {
          display: flex;
          align-items: center;
          span {
            height: 0.28rem;
            width: 0.28rem;
            text-align: center;
            line-height: 0.26rem;
            background: #3a78d3;
            color: white;
            border-radius: 50px;
            font-size: 0.26rem !important;
            margin-right: 5px;
          }
        }
        .Rem0_21 {
          line-height: 22px;
          color: #838690;
          padding-left: 25px;
        }
      }
    }
  }
  .number3 {
    width: 100%;
    height: 100vh;
    text-align: center;
    background: white;
    // margin-top: -4px;
    padding-bottom: 10%;
    .title {
      padding-top: 5vh;
    }
    .tab {
      margin-top: 30px;
      display: inline-block;
      padding: 10px 20px;
      border-radius: 20px;
      span {
        margin: 0 5px;
        width: 2.2rem;
        height: 0.6rem;
        line-height: 0.6rem;
        display: inline-block;
        color: #666666;
        background: #f4f6fa;
        border-radius: 5px;
      }
      .carouselActive {
        color: white !important;
        background: #255ca2;
        font-weight: bold;
      }
    }
    .carousel-img {
      display: flex;
      justify-content: center;

      .el-carousel {
        width: 48%;
        margin-top: 20px;
      }
      img {
        width: 100%;
        object-fit: contain;
      }
      > div {
        position: relative;
        margin-left: 3.5%;
        margin-top: 4%;
        text-align: start;
        width: 26%;
        p:nth-of-type(2) {
          line-height: 0.35rem !important;
        }
        .lineimg {
          width: 0.8rem !important;
          height: 0.5rem;
        }
      }
      .CustomButton {
        position: absolute;
        bottom: 25%;
      }
    }
  }
  .number4 {
    position: relative;
    img{
      position: absolute;
    }
    .contain {
      position: absolute;
      width: 80%;
      margin: 0 10%;
      .Rem0_4{
        margin-bottom: 6.5%;
        
      }
      .ul {
        width: 100%;
        display: flex;
        justify-content: space-between;
        opacity: 0.83;
        span {
          flex: 1;
          border: 1px solid;
          text-align: center;
          border: 1px solid #909090;
          border-radius: 5px;
          margin:0.2rem  0.11rem;
          margin-top: 20px;
          background: linear-gradient(to right, #555974, #5D616F);
          padding: 0.18rem 0;
          color: #E1E3EE;
          cursor: default;
        }
      }
    }
  }
}
</style>